import React from "react"
import { graphql } from "gatsby"
import TrustedBrands from '../Elements/TrustedBrands';

const Features = ({
  slice: {
    primary: { title },
    items,
  },
}) => {
  return (
    <>
      <TrustedBrands className="pt-80 md:pt-64 pb-24" />
      <div className="background1 xl:py-32 md:py-24 py-16 relative z-10">
        <div
          className="bg8 hidden lg:block -right-24 top-32 absolute -z-10"
        />
        <div
          className="bg7 hidden lg:block shipment1 absolute -z-10"
          style={{ left: '50%', top: '1024px', transform: 'translate(-65%, 0)' }}
        />
        <div
          className="bg8 hidden lg:block right-1/2 absolute -z-10"
          style={{ top: '780px', transform: 'translateX(50%) scale(-0.5, 0.5)' }}
        />
        <div
          className="bg8 hidden lg:block right-1/2 absolute -z-10"
          style={{ top: '2250px', transform: 'translateX(-10%) rotate(60deg)' }}
        />
        <div className="mx-16 px-4">
          <div className="flex xl:items-center items-start relative">
            <div className="stripes3 absolute 2xl:-left-40 xl:-left-16 -left-4 xl:top-auto top-4">
            </div>
            <h2 className="2xl:pl-0 xl:pl-24 lg:pl-32 pl-20 lg:text-7xl md:text-5xl sm:text-4xl text-3xl text-blue font-medium tracking-tighter title1 relative">
              {title?.text}
            </h2>
          </div>
        </div>
        <div className="mx-16">
          <div className="grid gap-16 lg:gap-y-32 my-16 lg:items-center lg:grid-cols-[repeat(3,_minmax(auto,_50%))]">
            {(items || []).map(({ description, poster, title }, index)=> {
              const imageBeforeText = index % 2;
              const text = (
                <>
                  <h3 className="text-blue lg:text-5xl md:text-4xl text-2xl font-medium md:mb-8 mb-5 tracking-tighter">
                    {title?.text}
                  </h3>
                  <p
                    className="xl:text-xl lg:text-lg text-md text-blue mb-1"
                    dangerouslySetInnerHTML={{ __html: description?.html }}
                  />
                </>
              );
              const translation = imageBeforeText ? 'translate-x-1/6' : '-translate-x-1/2';
              const image = (
                <img
                  alt={poster?.alt}
                  className={`drop-shadow-xl min-w-[768px] lg:min-w-0 transform ${translation} md:translate-x-0`}
                  loading="lazy"
                  srcSet={poster?.fluid.srcSetWebp.split('w,').at(-1).split(' ').at(0)}
                />
              );
              const [first, second, firstClass, secondClass] = imageBeforeText ? [
                image, text, 'col-span-2', 'col-span-1',
              ] : [
                text, image, 'col-span-1', 'col-span-2',
              ];
              return(
                <React.Fragment key={index}>
                  <div className={`hidden lg:block ${firstClass}`}>{first}</div>
                  <div className={`hidden lg:block ${secondClass}`}>{second}</div>
                  <div className="flex lg:hidden flex-col gap-8">
                    <div>{text}</div>
                    <div className="w-full">{image}</div>
                  </div>
                </React.Fragment>
              )
            })}
          </div>
        </div>
      </div>
    </>
  )
};

export default Features;

export const query = graphql`
  fragment ClickNShipFeaturesSlice on PrismicClickNShipDataBodyFeatures {
    id
    slice_type
    primary {
      title {
        text
      }
    }
    items {
      title {
        text
      }
      description {
        html
      }
      poster {
        alt
        fluid(maxWidth: 2400, maxHeight: 2400) {
          srcSet
          srcSetWebp
        }
      }
    }
  }
`

