import * as React from "react"
import { graphql } from "gatsby"
import CustomerExperience from "../Elements/CustomerExperience"

const GetStartedBanner = ({ slice }) => {
  const { primary } = slice
  const { trustedby_text: { text: trustedby_text } = {}, title } = primary
  return (
    <div className="bg-gradient xl:py-32 md:py-24 py-16 text-white">
      <CustomerExperience heading={title} trustedby_text={trustedby_text} items="clients" />
    </div>
  )
};

export default GetStartedBanner;

export const query = graphql`
  fragment ClickNShipGetStartedBannerSlice on PrismicClickNShipDataBodyGetStartedBanner {
    id
    slice_type
    primary {
      title {
        text
        html
      }
      trustedby_text {
        text
      }
    }
  }
`;
