import React from "react";
import { graphql } from "gatsby";

import { SliceZone } from "@prismicio/react"
import { components } from "../components/ClickNShip"
import SEO from "../components/seo"
import Layout from "../components/layout"

const ClickNShip = (props) => {
  const { data } = props
  if (!data) return null
  const pageData = data.prismicClickNShip
  const page = pageData.data || {}
  return (
    <Layout>
      <SEO
        context={props.pageContext}
        cpath={props.location.pathname}
      />
      <SliceZone
        slices={page.body}
        components={components}
        context={pageData}
        defaultComponent={() => null}
      />
    </Layout>
  );
};

export default ClickNShip;

export const query = graphql`
  query clickNShipQuery{
    prismicClickNShip {
    id
    type
    data {
      body{
          ... on PrismicSliceType {
            slice_type
          }
          ...ClickNShipHeroSlice
          ...ClickNShipFeaturesSlice
          ...ClickNShipAvailableServicesSlice
          ...ClickNShipFaqsSlice
          ...ClickNShipGetStartedBannerSlice
      }
    }
  }
  }
`
