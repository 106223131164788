import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"
import ButtonGetStarted from '../Buttons/ButtonGetStarted'
import ButtonWatchDemo from '../Buttons/ButtonWatchDemo'
import OutOfTheBoxContent from '../Elements/OutOfTheBoxContent'

const HeroSection = ({
  slice: {
    primary: {
      heading,
      sub_heading,
      hero_mobile_image,
      hero_image,
      powered_by_carriyo,
    },
  },
}) => {
  const {
    prismicSitesettings: {
      data: {
        site_logo,
      },
    },
  } = useStaticQuery(graphql`
    query ClickNShipImageQuery {
      prismicSitesettings {
        data {
          site_logo {
            fluid(maxWidth: 320, maxHeight: 140) {
              srcWebp
              srcSetWebp
            }
          }
        }
      }
    }
  `)
  return (
    <>
      <div className="background1 md:pt-52 pt-40 pb-32">
        <div className="max-w-7xl mx-auto px-4 z-10 relative">
          <div className="relative text-center mb-8">
            <span className="stripes1Close absolute"></span>
            <h1 className="xl:text-8xl lg:text-7xl sm:text-6xl text-5xl text-blue font-medium tracking-tighter mb-1">
              {heading?.text}
            </h1>
            {powered_by_carriyo && (
              <div className="flex gap-x-2 items-center justify-center">
                <p className="text-blue text-md text-center m-0">
                  powered by
                </p>
                <img
                  loading="lazy"
                  src={site_logo.fluid.srcWebp}
                  srcSet={site_logo.fluid.srcSetWebp}
                  width={103}
                  height={22}
                  alt="Site Logo"
                  className="mb-0"
                />
              </div>
            )}
            <span className="stripes2Close absolute"></span>
          </div>
          <p className="text-blue text-xl mx-auto text-center mb-3">{sub_heading}</p>
          <div className="btns flex justify-center items-center text-center md:flex-row flex-col md:gap-4">
            <ButtonGetStarted />
            <ButtonWatchDemo />
          </div>
        </div>
        <div>
          <div className="md:block hidden lg:-mt-20 -mt-12" >
            <GatsbyImage image={getImage(hero_image)} alt={heading && heading.text} />
          </div>
          <div className="md:hidden block mt-8">
            {/* <GatsbyImage image={getImage(hero_mobile_image)} alt={heading && heading.text} />  */}
            <StaticImage src="../../assets/images/Home/hero-bg_mob2.png"  /> 
          </div>
        </div>
      </div>
      <OutOfTheBoxContent className="-mb-64"/>
    </>
  );
};

export default HeroSection;

export const query = graphql`
  fragment ClickNShipHeroSlice on PrismicClickNShipDataBodyHero {
    id
    slice_type
    primary {
      heading {
        text
      }
      sub_heading
      hero_mobile_image {
        gatsbyImageData(layout: FULL_WIDTH)
      }
      hero_image {
        gatsbyImageData(layout: FULL_WIDTH)
      }
      powered_by_carriyo
    }
  }
`;
