import HeroSection from "./HeroSection"
import Features from "./Features";
import AvailableServices from "./AvailableServices"
import FaqSection from "./FaqSection"
import GetStartedBanner from "./GetStartedBanner"

export const components = {
  hero: HeroSection,
  features: Features,
  available_services: AvailableServices,
  faqs: FaqSection,
  get_started_banner: GetStartedBanner,
}
