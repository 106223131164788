import * as React from "react"
import { graphql } from "gatsby"

import FaqSection from '../Elements/FaqSection';

export default (props) => (
  <div className="relative z-10">
    <div className="bg8 left-0 md:-left-24 -top-20 md:-top-32 absolute -z-10 transform scale-50 md:scale-75 rotate-45" />
    <FaqSection {...props} className="p-6" />
  </div>
);

export const query = graphql`
  fragment ClickNShipFaqsSlice on PrismicClickNShipDataBodyFaqs{
    id
    slice_type
    items {
      faq {
        document {
          ... on PrismicFaq {
            id
            data {
              question {
                text
                html
              }
              answer {
                text
                html
                richText
              }
            }
          }
        }
      }
    }
  }
`
