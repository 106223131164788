import React from "react"
import { graphql } from "gatsby"
import { startCase } from "lodash"
import { FormButton } from "../../frontend-shared/components";

const color = {
  'same-day': 'primary',
  'next-day': 'primary',
  'express': 'secondary',
  'international-express': 'tertiary',
};

const Tag = ({ type }) => (
  <FormButton color={color[type] || color['same-day']} size="sm" type="tag">
    {startCase(type)}
  </FormButton>
);

const AvailableServices = ({
  slice: {
    primary: {
      currency,
      clause,
      title,
    },
    items,
  },
}) => {
  return (
    <div className="bg-gradient flex flex-col items-center gap-16 px-8 md:px-16 py-16 md:py-32 w-full text-white relative z-10">
      <div
        className="bg9 -top-20 lg:top-16 right-2 absolute -z-10 transform -translate-y-1/2 translate-x-40 lg:translate-x-16 scale-50 lg:scale-100"
      />
      <div className="w-5/6 md:w-4/6">
        <h2 className="xl:text-6xl lg:text-5xl text-4xl font-medium tracking-tighter">
          {title?.text}
        </h2>
      </div>
      <div className="flex flex-col gap-8 text-center">
        {(items || []).map(({ base_price, description, scope, type, unit }) => (
          <div className="background2adaptive flex flex-col items-center md:flex-row gap-8 lg:gap-16 p-8 md:h-24 rounded-xl">
            <div className="w-28 text-blue font-bold md:text-start">{startCase(scope)}</div>
            <div className="flex justify-center w-64">
              <Tag type={type} />
            </div>
            <div className="w-40 lg:w-48">{description?.text}</div>
            <div className="w-48 font-bold">{`from ${base_price} ${currency} / ${unit}`}</div>
          </div>
        ))}
      </div>
      <p>
        {clause?.text}
      </p>
    </div>
  );
};

export default AvailableServices;

export const query = graphql`
  fragment ClickNShipAvailableServicesSlice on PrismicClickNShipDataBodyAvailableServices {
    id
    slice_type
    primary {
      title {
          text
      }
      clause {
          text
      }
      currency
    }
    items {
      base_price
      description {
        text
      }
      scope
      type
      unit
    }
  }
`;
